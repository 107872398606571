import { forwardRef, useState } from "react";
import Swal from 'sweetalert2';
import './modalSendResume.css'
import { Dialog, DialogContent, DialogTitle, Slide, Box, Button, Typography,DialogContentText,DialogActions} from "@mui/material";
import FormSendResume from "./FormSendResume";
import { sendDataToAPI } from "../../services/requestsToAPI";
import mailSending from '../../assets/images/mail-sending.gif';
//import mailSend from '../../assets/images/mail-send.gif'
import mailSend from '../../assets/images/isa.jpg';
import { eventAnalytics } from "../../services/firebaseConfig";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalSendResume({recordType,odQ1,odQ2,odQ3, modalActive, setModalActive, titleJob, idJob, numberJob }) {
    
    const steps = [titleJob?`Apply for ${titleJob} (${numberJob})`:"Complete your information"];
    const [currentForm, setCurrentForm] = useState(0);
    const [checkActive, setCheckActive] = useState(false);
    const final = currentForm === steps.length;
    const inicio = currentForm === 0;
    const [contactInformation, setContactInformation] = useState()
    const [sendingMessage, setSendingMessage] = useState(false)
    const [emptyResumeFile, setEmptyResumeFile] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    let applicationId = "";

    const resetForm = () => {
        setCheckActive(false)
        setContactInformation()
    }

    const handleClose = () => {
        resetForm()
        setCurrentForm(0)
        setModalActive(false);
    };

    const handleBack = () => {
        if (inicio) {
            return handleClose();
        }
        setCurrentForm((prevCurrentForm) => prevCurrentForm - 1);
    };
  
    
    const handleNext = () => {
        console.log(contactInformation);
        console.log('numberJob',numberJob);
        console.log('numberJob',numberJob == 'JN -062019-3978');
    
        if (validateForm(contactInformation)) {
            // Check if all answers are true
            // if (!['answer1', 'answer2', 'answer3'].every((key) => contactInformation[key] === true)) {
            //     setOpenDialog(true); // Show modal if any answer is false
            //     return;
            // }
    
    
            // if (!checkActive && inicio) {
            //     return setCurrentForm(steps.length);
            // }
    
    
            //if (final) {
            sendMessage("/email/sendresume", contactInformation);
            resetForm();
            return handleClose();
            //}
    
    
            setCurrentForm((prevCurrentForm) => prevCurrentForm + 1);
        }
    };
    
    
    const validateForm = (object) => {
        for (const key in object) {
            const item = object[key];
    
    
            if (item === "") {
                const thisInput = document.getElementById(`${key}Input`);
                thisInput.placeholder = `Please enter ${key}`;
                thisInput.focus();
                return false;
            }

            if(key === "privacyPolicy" && item === false){
                return false;
            }
    
    
            if (key === "fileInput" && !item) {
                setEmptyResumeFile(true);
                return false;
            }
        }
    
    
        return true; // All validations passed
    };
    

    const handlerChange = (setState, currentTarget) => {
        let {value, id} = currentTarget
        document.getElementById(id).placeholder = ""
        setState(value)
    }

    const FormFinal = () => {
        return (
            <Box className="flex col w100 center" sx={{ p: 1 }}>
                {/* <img
                    style={{minHeight:"120px"}}
                    width={120}
                    height={"auto"}
                    src={sendingMessage?mailSending:mailSend}
                    alt="message"
                />
                <Typography sx={{ mt: 2, mb: 1, textAlign:"center" }} variant="h6">
                Do not exit out from the website a window will pop up with a link to your interview with ISA.
                <br />
                <br />
                Also, you will find the link sent to the email address you provided in the form. Please check your spam folder.
                <br />
                <br />
                If you have not received the link, please apply again and ensure the email address is correct.
                </Typography>                */}

                <Button
                    color="white"                   
                    onClick={handleNext}    
                    sx={{
                        backgroundColor: "red",
                        color: "white",
                        "&:hover": {
                            backgroundColor: "darkred", // Change the hover color
                        },
                    }}              
                >
                    Submit my application
                </Button>


                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                </Box>
            </Box>
        );
    };

    const registerEventAnalytics = () => {
        if(titleJob){
            eventAnalytics("New App General")
            eventAnalytics(`New App ${numberJob}`)
        }else{
            eventAnalytics("New App Candidate Pool")
        }
    }

    const sendMessage = async (param, data) => {
        if (titleJob) {
            data.titleJob = titleJob;
            data.numberJob = numberJob;
            data.idJobSalesforce = idJob;
            data.recordtype = recordType;
        }

        data.fileBase64 = data.fileInput.base64.split(",")[1];
        data.fileExt = data.fileInput.file.name.split(".").pop().toLowerCase();

        setIsLoading(true); // Show the loading spinner

        let alert1 = Swal.fire({
            title: 'Please Wait',
            html: `<p style="font-family: Monserrat, sans-serif;">We're submitting your application</p>`,
            //icon: 'info',
            imageWidth: 150,
            imageHeight: 150,
            imageUrl:'https://png.pngtree.com/png-vector/20220608/ourmid/pngtree-red-clock-icon-on-white-background-png-image_4887940.png',
            showConfirmButton: false
        });

        try {
            const response = await sendDataToAPI(param, data);
            console.log(response);

            if ('application' in response && response.application) {
                const applicationId = response.application;
            }           

            if (response.status === 200) {
                Swal.fire({
                    title: 'Thank you!',
                    html: `
                        <p style="font-family: Monserrat, sans-serif;">We received your application!</p>
                        <br>
                        <button 
                            style="
                            
                                background-color: red; 
                                color: white; 
                                border: none; 
                                padding: 10px 20px; 
                                border-radius: 5px; 
                                cursor: pointer; 
                                font-size: 16px;"
                            onclick="window.open('https://asapstaffingservices.my.site.com/AIOD/isa?app_id=${response.application}', '_blank')">
                            Start chat interview
                        </button>
                        <button 
                            style="
                            
                                background-color: red; 
                                color: white; 
                                border: none; 
                                padding: 10px 20px; 
                                border-radius: 5px; 
                                cursor: pointer; 
                                font-size: 16px;"
                            onclick="window.open('https://hirepluto.com/apply/1?externalAppId=${response.application}&integration=salesforce-asap', '_blank')">
                            Start voice interview
                        </button>
                    `,
                    icon: 'success',
                    showConfirmButton: false
                });
                registerEventAnalytics();
            } else {
                console.error('Error sending data:', response);
            }
        } catch (error) {
            console.error('An error occurred:', error);
        } finally {
            setIsLoading(false); // Hide the loading spinner
            alert1.close();
        }
    };

    return (
        
        <div>           

            <Dialog open={isLoading} onClose={() => setIsLoading(false)}>
                <DialogTitle>Application Not Qualified</DialogTitle>
                <DialogContent  className="spinner">
                    <DialogContentText>
                        Thanks for your interest! However, based on the 3 must-have requirements, you do not meet the criteria to get an interview for this job.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsLoading(false)} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog
                open={modalActive}
                TransitionComponent={Transition}
                // keepMounted
                className="modalSendResume"
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{final ? sendingMessage?"Sending message":"Almost Done" : steps[currentForm]}</DialogTitle>
                <DialogContent className="flex center" sx={{ overflowY: "visible"}}>
                    <Box sx={{ width: "75%", minWidth: "40vw" }}>
                        <Box className="flex col w100" sx={{ p: 1 }}>
                            {currentForm === 0 ? 
                                <FormSendResume
                                    numberJob = {numberJob}
                                    odQ1 = {odQ1}
                                    odQ2 = {odQ2}
                                    odQ3 = {odQ3}
                                    recordType = {recordType}
                                    modalActive={modalActive} 
                                    handlerChange={handlerChange} 
                                    contactInformation={contactInformation} 
                                    setContactInformation={setContactInformation} 
                                    checkActive={checkActive} 
                                    setCheckActive={setCheckActive}
                                    emptyResumeFile={emptyResumeFile} 
                                    setEmptyResumeFile={setEmptyResumeFile}
                                />  
                            :
                                <FormFinal />
                            }
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                            <Button
                                color="inherit"
                                disabled={final}
                                onClick={handleBack}
                                sx={{ mr: 1, opacity: final ? 0 : 1 }}
                            >
                                Back
                            </Button>
                        <Box sx={{ flex: "1 1 auto" }} />

                        {!final && (
                            <Button onClick={handleNext} disabled={sendingMessage}>
                                {checkActive && currentForm === 0 ? "Next" : "Send Resume"}
                            </Button>
                        )}
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>

            

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Application Not Qualified</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Thanks for your interest! However, based on the 3 must-have requirements, you do not meet the criteria to get an interview for this job.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}
